import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

//import { Link } from "gatsby"
import Link from '../components/Link'

import Layout from '../components/layout'
import SEO from '../components/seo'
import '../css/services.scss'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import CustomButton from '../components/custom-button/custom-button.component.jsx'

import logo from '../images/logo.svg'
import servivesLeft from '../images/services-left.svg'
import servivesRight from '../images/services-right.svg'
import kpi from '../images/services-kpi.svg'
import idengager from '../images/Services-idengager.svg'
import idataworkers from '../images/Services-idataworkers.svg'
import iopsapps from '../images/Services-iopsapps.svg'
import confirmation from '../images/services-confirmation.svg'
import service from '../images/servicerequest.svg'
import work from '../images/confirm.svg'
import contracts from '../images/services-contracts.svg'
import dashboard from '../images/services-dashboard.svg'

const Services = ({ data }) => (
	<Fragment>
		<Helmet>
			<meta name="robots" content="noindex" />
		</Helmet>
		<Layout>
			{/* <SEO title="Real Estate Cloud Ready Platform" /> */}
			<div className="services-page">
				<div className="Header-parent">
					<MDXRenderer>{data.Header.nodes[0].body}</MDXRenderer>
				</div>
				<div className="page-content services-content">
					<div className="services-section">
						<div className="header-content">
							<div className="header-items">
								<h1>Service Management</h1>
								<div className="logo-parent">
									<img src={logo} alt="logo" />
								</div>
								<div className="hint">
									<h3>
										<span>
											Facility and Service Management Platform
										</span>
										<span>
											{' '}
											Your gateway to take control of your
											facilites and service operations.
										</span>
									</h3>
								</div>
								<CustomButton className="link-wrapper">
									<Link
										className="demo-request"
										data-track-location="service"
										to="/pricing"
									>
										Request a Demo
									</Link>
								</CustomButton>
							</div>
							<div className="header-imgs">
								<img src={servivesLeft} alt="services-left" />
								<img src={servivesRight} alt="services-right" />
							</div>
						</div>
					</div>
					<section className="products-content" id="products-content">
						<h1 className="product-header">Offering</h1>
						<div className="products-container">
							<div className="product-wrapper">
								<div className="product-parent reverse-parent">
									<div className="product-details">
										<h3 className="title">
											Operations Excellence for service and
											facility operation
										</h3>
										<p className="subtitle">
											icloudready Service Management being fully
											integrated with Your System of Records,
											helps facility management and maintance
											departments to achieve the highest level of
											service effectiveness, it provides
											companies with the right tools that will
											help them manage their facilites, warranty
											contracts or SLA more smoothly and provide
											prompt support to their clients.
										</p>
										<CustomButton className="link-wrapper">
											<Link
												className="product-btn"
												data-track-location="service"
												to="/pricing"
											>
												Request a Demo
											</Link>
										</CustomButton>
									</div>
									<div className="product-img">
										<img
											src={iopsapps}
											className="item-img"
											alt="iopsapps"
										/>
									</div>
								</div>
							</div>
							<div className="product-wrapper">
								<div className="product-parent">
									<div className="product-details">
										<h3 className="title">Actionable Insights</h3>
										<p className="subtitle">
											With the executives' scarce, yet valuable
											time, the need for prominent dashboards
											that incapsulates all important figures and
											puts them together in an easy to read
											format. Not to mention the numerous amount
											of agents at the ready to carry out actions
											in a blink, all automated and all accurate
											to the point!.
										</p>
										<CustomButton className="link-wrapper">
											<Link
												className="product-btn"
												data-track-location="service"
												to="/pricing"
											>
												Request a Demo
											</Link>
										</CustomButton>
									</div>
									<div className="product-img">
										<img
											src={idataworkers}
											className="item-img"
											alt="idataworkers"
										/>
									</div>
								</div>
							</div>
							<div className="product-wrapper">
								<div className="product-parent reverse-parent">
									<div className="product-details">
										<h3 className="title">
											Digital engagement app socially built for
											your tenant
										</h3>
										<p className="subtitle">
											The mobile application is now a necessity
											to bring your business closer to your
											customers, where they can make use of all
											your availed services at the palm of their
											hands, and the convenience of their
											homes...
										</p>
										<CustomButton className="link-wrapper">
											<Link
												className="product-btn"
												data-track-location="service"
												to="/pricing"
											>
												Request a Demo
											</Link>
										</CustomButton>
									</div>
									<div className="product-img">
										<img
											src={idengager}
											className="item-img"
											alt="confirmation"
										/>
									</div>
								</div>
							</div>
							<div className="product-wrapper">
								<div className="product-parent">
									<div className="product-details">
										<h3 className="title">
											Boost your services team productivity with
											OKR solution build for services management
										</h3>
										<p className="subtitle">
											Effectively breakdown your teams' KPIs into
											measurable OKRs, connect them to the
											company goals and bigger picture at all
											times.
										</p>
										<CustomButton className="link-wrapper">
											<Link
												className="product-btn"
												data-track-location="service"
												to="/pricing"
											>
												Request a Demo
											</Link>
										</CustomButton>
									</div>
									<div className="product-img">
										<img src={kpi} className="item-img" alt="kpi" />
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
				<div className="footer-section">
					<MDXRenderer>{data.Footer.nodes[0].body}</MDXRenderer>
				</div>
			</div>
		</Layout>
	</Fragment>
)
export const pageQuery = graphql`
	query ServicesQuery {
		Header: allMdx(filter: { frontmatter: { title: { eq: "Header" } } }) {
			nodes {
				body
			}
		}
		Footer: allMdx(filter: { frontmatter: { title: { eq: "Footer" } } }) {
			nodes {
				body
			}
		}
	}
`
export default Services
